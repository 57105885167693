@font-face {
  font-family: 'Graphik';
  src: url('assets/fonts/Graphik-Semibold-Web.v2.woff2') format('woff2'),
  url('assets/fonts/Graphik-Semibold-Web.v2.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('assets/fonts/Graphik-Regular-Web.v2.woff2') format('woff2'),
  url('assets/fonts/Graphik-Regular-Web.v2.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('assets/fonts/Graphik-Medium-Web.v1.woff2') format('woff2'),
    url('assets/fonts/Graphik-Medium-Web.v1.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Petersburg';
  src: url('assets/fonts/Petersburg-Regular-Alle.v3.woff2') format('woff2'),
    url('assets/fonts/Petersburg-Regular-Alle.v3.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Petersburg';
  src: url('assets/fonts/Petersburg-Italic.woff2') format('woff2'),
  url('assets/fonts/Petersburg-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

body {
  margin: 0;
  font-family: "Graphik", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  margin-block-start: 0;
  margin-block-end: 0;
}
